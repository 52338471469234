var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[(_vm.detalPengajuan.status_peminjaman == 'acc' && _vm.detalPengajuan.tgl_pengambilan == null)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Petugas pencatat","label-for":"petugas_pengambilan"}},[_c('validation-provider',{attrs:{"rules":"required","name":"keterangan Alat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.input.petugas_pengambilan),callback:function ($$v) {_vm.$set(_vm.input, "petugas_pengambilan", $$v)},expression:"input.petugas_pengambilan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3851746349)})],1),_c('b-form-group',{attrs:{"label":"Nama Pengambil Alat","label-for":"nama_pengambil"}},[_c('validation-provider',{attrs:{"rules":"required","name":"keterangan Alat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.input.nama_pengambil),callback:function ($$v) {_vm.$set(_vm.input, "nama_pengambil", $$v)},expression:"input.nama_pengambil"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,284479072)})],1),_c('b-form-group',{attrs:{"label":"No Hp User Pengambil","label-for":"no_hp_pengambil"}},[_c('validation-provider',{attrs:{"rules":"required","name":"keterangan Alat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.input.no_hp_pengambil),callback:function ($$v) {_vm.$set(_vm.input, "no_hp_pengambil", $$v)},expression:"input.no_hp_pengambil"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2462891429)})],1),_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Uang Sewa Rp.")]),_c('validation-provider',{attrs:{"name":"Nominall Pembayaran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('currency-input',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.input.uang_sewa),callback:function ($$v) {_vm.$set(_vm.input, "uang_sewa", $$v)},expression:"input.uang_sewa"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,363902686)})],1)],1):_vm._e(),(_vm.detalPengajuan.status_peminjaman == 'acc' && _vm.detalPengajuan.tgl_pengambilan != null)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Petugas Peneriam Alat","label-for":"petugas_penerima"}},[_c('validation-provider',{attrs:{"rules":"required","name":"keterangan Alat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.input.petugas_penerima),callback:function ($$v) {_vm.$set(_vm.input, "petugas_penerima", $$v)},expression:"input.petugas_penerima"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3881154396)})],1),_c('b-form-group',{attrs:{"label":"Nama Pengembali Alat","label-for":"nama_pengembalian"}},[_c('validation-provider',{attrs:{"rules":"required","name":"keterangan Alat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.input.nama_pengembalian),callback:function ($$v) {_vm.$set(_vm.input, "nama_pengembalian", $$v)},expression:"input.nama_pengembalian"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,397495882)})],1),_c('b-form-group',{attrs:{"label":"Kondisi Alat Saat dikembalikan"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Kondisi Alat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"state":"errors.length > 0 ? false : null","label":"title","options":_vm.options},model:{value:(_vm.input.status_barang),callback:function ($$v) {_vm.$set(_vm.input, "status_barang", $$v)},expression:"input.status_barang"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,514735694)})],1),_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Uang Denda Rp.")]),_c('validation-provider',{attrs:{"name":"Nominall Pembayaran"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('currency-input',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.input.uang_denda),callback:function ($$v) {_vm.$set(_vm.input, "uang_denda", $$v)},expression:"input.uang_denda"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3982627412)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Catatan"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Catatan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Catatan Pemingjaman Penggunaaan ALat","rows":"3","state":errors.length > 0 ? false : null},model:{value:(_vm.input.catatan),callback:function ($$v) {_vm.$set(_vm.input, "catatan", $$v)},expression:"input.catatan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.detalPengajuan.status_peminjaman == 'acc' && _vm.detalPengajuan.tgl_pengambilan == null)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mb-1",attrs:{"variant":"info","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.actPengajuan('pengambilan')}}},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"15"}}),_vm._v(" Pengambilan Alat Produksi ")],1)],1):_vm._e(),(_vm.detalPengajuan.status_peminjaman == 'acc' && _vm.detalPengajuan.tgl_pengambilan != null)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mb-1",attrs:{"variant":"success","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.actPengajuan('pengembalian')}}},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"15"}}),_vm._v(" Pengembalian Alat Produksi ")],1)],1):_vm._e(),(_vm.penecekan_bentrok.st_bentrok == '0')?_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mb-1",attrs:{"variant":"primary","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.actPengajuan('acc')}}},[_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"15"}}),_vm._v(" ACC Pengajuan ")],1)],1):_vm._e(),(_vm.detalPengajuan.status_peminjaman != 'acc')?_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mb-1",attrs:{"variant":"warning","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.actPengajuan('ditolak')}}},[_c('feather-icon',{attrs:{"icon":" CheckIcon","size":"15"}}),_vm._v(" Tolak Pengajuan ")],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }