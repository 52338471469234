<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-xl-12">
        <div class="card card-statistics"><!----><!---->
          <div class="card-body statistics-body" style="margin-bottom: -20px;"><!----><!---->
            <div class="row">
              <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                <a href="#pengajuan">
                  <div class="media" @click="loadData('pengajuan', 'Pengajuan Peminjaman Alat')">
                    <div class="media-aside mr-2 align-self-start"><span
                        class="b-avatar badge-light-warning rounded-circle" style="width: 48px; height: 48px;">
                        <feather-icon icon="AirplayIcon" size='25' />
                      </span></div>
                    <div class="media-body my-auto">
                      <h4 class="font-weight-bolder mb-0"> {{ countData[1].jm_pengajuan }} </h4>
                      <p class="card-text font-small-3 mb-0"> Pengajuan Peminjaman </p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                <a href="#ditolak">
                  <div class="media" @click="loadData('ditolak', 'Pengajuan Ditolak')">
                    <div class="media-aside mr-2 align-self-start">
                      <span class="b-avatar badge-light-danger rounded-circle" style="width: 48px; height: 48px;">
                        <feather-icon icon="AlertTriangleIcon" size='25' />
                      </span>
                    </div>
                    <div class="media-body my-auto">
                      <h4 class="font-weight-bolder mb-0">{{ countData[2].jm_pengajuan }}</h4>
                      <p class="card-text font-small-3 mb-0"> Pengajuan Ditolak </p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-sm-6 col-xl-3">
                <a href="#acc">
                  <div class="media" @click="loadData('acc', 'Pengajuan Tervalidasi')">
                    <div class="media-aside mr-2 align-self-start"><span
                        class="b-avatar badge-light-success rounded-circle" style="width: 48px; height: 48px;">
                        <feather-icon icon="CheckCircleIcon" size='25' />
                      </span></div>
                    <div class="media-body my-auto">
                      <h4 class="font-weight-bolder mb-0"> {{ countData[0].jm_pengajuan }}</h4>
                      <p class="card-text font-small-3 mb-0">Pengajuan Tervalidasi</p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-sm-6 col-xl-3">
                <a href="#pengembalian">
                  <div class="media" @click="loadData('pengembalian', 'Pengembalian Alat')">
                    <div class="media-aside mr-2 align-self-start"><span
                        class="b-avatar badge-light-primary rounded-circle" style="width: 48px; height: 48px;">
                        <feather-icon icon="ArchiveIcon" size='25' />
                      </span></div>
                    <div class="media-body my-auto">
                      <h4 class="font-weight-bolder mb-0"> {{ countData[3].jm_pengajuan }} </h4>
                      <p class="card-text font-small-3 mb-0">Pengembalian Alat</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div><!----><!---->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    countData: {}
  },
  emits: ['loadData'],
  methods: {
    loadData(act, label) {
      this.$emit('loadData', act, label)
    }

  }
}
</script>

<style></style>