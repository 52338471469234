<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12" v-if="detalPengajuan.status_peminjaman == 'acc' && detalPengajuan.tgl_pengambilan == null">
            <b-form-group label="Petugas pencatat" label-for="petugas_pengambilan">
              <validation-provider #default="{ errors }" rules="required" name="keterangan Alat">
                <b-form-input v-model="input.petugas_pengambilan" :state="errors.length > 0 ? false : null"
                  placeholder="" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Nama Pengambil Alat" label-for="nama_pengambil">
              <validation-provider #default="{ errors }" rules="required" name="keterangan Alat">
                <b-form-input v-model="input.nama_pengambil" :state="errors.length > 0 ? false : null" placeholder="" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="No Hp User Pengambil" label-for="no_hp_pengambil">
              <validation-provider #default="{ errors }" rules="required" name="keterangan Alat">
                <b-form-input v-model="input.no_hp_pengambil" :state="errors.length > 0 ? false : null"
                  placeholder="" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <label for="InputHelp">Uang Sewa Rp.</label>
              <validation-provider #default="{ errors }" name="Nominall Pembayaran" rules="required">
                <currency-input v-model="input.uang_sewa" class="form-control"
                  :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="detalPengajuan.status_peminjaman == 'acc' && detalPengajuan.tgl_pengambilan != null">
            <b-form-group label="Petugas Peneriam Alat" label-for="petugas_penerima">
              <validation-provider #default="{ errors }" rules="required" name="keterangan Alat">
                <b-form-input v-model="input.petugas_penerima" :state="errors.length > 0 ? false : null"
                  placeholder="" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Nama Pengembali Alat" label-for="nama_pengembalian">
              <validation-provider #default="{ errors }" rules="required" name="keterangan Alat">
                <b-form-input v-model="input.nama_pengembalian" :state="errors.length > 0 ? false : null"
                  placeholder="" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Kondisi Alat Saat dikembalikan">
              <validation-provider #default="{ errors }" rules="required" name="Kondisi Alat">
                <v-select v-model="input.status_barang" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  state="errors.length > 0 ? false : null" label="title" :options="options" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>


            </b-form-group>

            <b-form-group>
              <label for="InputHelp">Uang Denda Rp.</label>
              <validation-provider #default="{ errors }" name="Nominall Pembayaran">
                <currency-input v-model="input.uang_denda" class="form-control"
                  :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Catatan">
              <validation-provider #default="{ errors }" rules="required" name="Catatan">
                <b-form-textarea id="textarea-default" placeholder="Catatan Pemingjaman Penggunaaan ALat" rows="3"
                  v-model="input.catatan" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6" v-if="detalPengajuan.status_peminjaman == 'acc' && detalPengajuan.tgl_pengambilan == null">
            <b-button class="mb-1" variant="info" block type="submit" @click.prevent="actPengajuan('pengambilan')">
              <feather-icon icon="UserIcon" size='15' /> Pengambilan Alat Produksi
            </b-button>
          </b-col>
          <b-col cols="6" v-if="detalPengajuan.status_peminjaman == 'acc' && detalPengajuan.tgl_pengambilan != null">
            <b-button class="mb-1" variant="success" block type="submit" @click.prevent="actPengajuan('pengembalian')">
              <feather-icon icon="UserIcon" size='15' /> Pengembalian Alat Produksi
            </b-button>
          </b-col>
          <b-col cols="6" v-if="penecekan_bentrok.st_bentrok == '0'">
            <b-button class="mb-1" variant="primary" block type="submit" @click.prevent="actPengajuan('acc')">
              <feather-icon icon="CheckIcon" size='15' /> ACC Pengajuan
            </b-button>
          </b-col>
          <b-col cols="6" v-if="detalPengajuan.status_peminjaman != 'acc'">
            <b-button class="mb-1" variant="warning" block type="submit" @click.prevent="actPengajuan('ditolak')">
              <feather-icon icon=" CheckIcon" size='15' /> Tolak Pengajuan
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import CurrencyInput from './CurrencyInput.vue';
import flatPickr from 'vue-flatpickr-component'
import {
  BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BForm,
  BCard, BAlert, BCardBody, BFormTextarea, BCardText
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required
} from '@validations'
export default {
  components: {
    BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BForm,
    BCard, BAlert, BCardBody, BFormTextarea, BCardText,
    ValidationProvider, ValidationObserver, flatPickr, CurrencyInput, vSelect
  },
  data() {
    return {
      required,
      input: {
        tgl_pengambilan: '',
        petugas_pengambilan: '',
        nama_pengambil: '',
        no_hp_pengambil: '',
        uang_sewa: '',
        catatan: '',
        petugas_penerima: '',
        nama_pengembalian: '',
        status_barang: '',
        uang_denda: '',
        id_item_alat_prodiksi: this.detalPengajuan.id_item_alat_prodiksi
      },
      options: [
        { title: "Tidak Bisa Digunakan" },
        { title: "Rusak Berat" },
        { title: "Rusak Ringan" },
        { title: "Baik" }
      ]
    }
  },
  props: {
    detalPengajuan: {},
    penecekan_bentrok: {},
    histori_alat: {}
  },
  emits: ['prosesPengajuan'],
  methods: {
    actPengajuan(label) {
      this.$refs.simpleRules.validate().then(success => {
        console.log(this.input)
        if (success) {
          this.$emit('prosesPengajuan', label, this.input, this.detalPengajuan)
        }
      })
    },
    onInput(value) {
      // Remove non-numeric characters
      const numericValue = value.replace(/[^0-9.]/g, '');
      this.rawValue = numericValue;

      // Format as currency
      this.formattedValue = this.formatCurrency(numericValue);
    },
    formatCurrency(value) {
      if (!value) return '';

      // Convert to a number and format as currency
      const number = parseFloat(value);
      return number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      });
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>