<template>
  <b-row>
    <b-col md="5" sm="8" class="my-1">
      <strong> <feather-icon icon="AlignJustifyIcon" size='15' /> Daftar {{ label }}</strong>
    </b-col>
    <b-col md="1" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" />
      </b-form-group>
    </b-col>
    <b-col md="6" class="my-1">
      <b-form-group label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :show-head="false" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
        :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(id)="data">
          <b-row class="match-height">
            <b-col md="6">
              <b-alert variant="" show style="margin-left: -30px;">
                <div class="alert-body">
                  <span class="text-primary">Mitra UMKM : {{ data.item.nama_umkm }}</span><br>
                  <span class="text-secondary">Jenis Usaha {{ data.item.jenis_usaha }}</span><br>
                  <small>No Hp : {{ data.item.no_hp }}</small><br>
                  <small>Alamat : {{ data.item.alamat }}</small>
                </div>
              </b-alert>
            </b-col>
            <b-col md="4">
              <b-alert variant="success" show style="" v-show="data.item.status_peminjaman == 'acc'">
                <div class="alert-body">
                  <span><small>Alat yang dipinjam</small><br>{{ data.item.nama_alat }} <br><strong>
                      Kode Alat {{ data.item.kode_alat }}
                      {{ data.item.id }}</strong></span><br>
                  <small>Tanggal Peminjaman<br>{{ data.item.tgl_mulai }} - {{ data.item.tgl_selesai }}</small>
                </div>
              </b-alert>

              <b-alert variant="warning" show style="" v-show="data.item.status_peminjaman == 'pengajuan'">
                <div class="alert-body">
                  <span><small>Alat yang dipinjam</small><br>{{ data.item.nama_alat }} <br><strong>
                      Kode Alat {{ data.item.kode_alat }}
                      {{ data.item.id }}</strong></span><br>
                  <small>Tanggal Peminjaman<br>{{ data.item.tgl_mulai }} - {{ data.item.tgl_selesai }}</small>
                </div>
              </b-alert>

              <b-alert variant="danger" show style="" v-show="data.item.status_peminjaman == 'ditolak'">
                <div class="alert-body">
                  <span><small>Alat yang dipinjam</small><br>{{ data.item.nama_alat }} <br><strong>
                      Kode Alat {{ data.item.kode_alat }}
                      {{ data.item.id }}</strong></span><br>
                  <small>Tanggal Peminjaman<br>{{ data.item.tgl_mulai }} - {{ data.item.tgl_selesai }}</small>
                </div>
              </b-alert>

              <b-alert variant="primary" show style="" v-show="data.item.status_peminjaman == 'pengembalian'">
                <div class="alert-body">
                  <span><small>Alat yang dipinjam</small><br>{{ data.item.nama_alat }} <br><strong>
                      Kode Alat {{ data.item.kode_alat }}
                      {{ data.item.id }}</strong></span><br>
                  <small>Tanggal Peminjaman<br>{{ data.item.tgl_mulai }} - {{ data.item.tgl_selesai }}</small>
                </div>
              </b-alert>
            </b-col>
            <b-col md="2">
              <b-button variant="outline-primary" class="btn  btn-sm btn-block" v-b-modal.modal-1
                @click="DetailPengajuan(data.item)">
                <feather-icon icon="AlignJustifyIcon" size='15' /> Detail
                <br>Pengajuan
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
  BCard, BAlert, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BTable, BAlert,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton, BCard, BCardBody
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id', label: 'Id',
          thStyle: {
            display: 'none'
          }
        },

      ],

      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  props: {
    lis_pengajuan: {},
    label: {}
  },
  computed: {
    items: {
      get: function () {
        return this.lis_pengajuan;
      },
      set: function (newValue) {
        return this.lis_pengajuan = newValue;
      }
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  emits: ['DetailPengajuan'],
  methods: {
    DetailPengajuan(detail) {
      this.$emit('DetailPengajuan', detail)
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>