<template>
  <div>
    <b-modal id="modal-1" title="Detail Peminjaman Alat Produksi Bersama" ok-disabled size="lg" hide-footer hide-header>
      <div style="float: right;">
        <b-badge @click="close"><feather-icon icon="XIcon" size='15' /></b-badge>
      </div>
      <h4><feather-icon icon="GridIcon" size='15' /> Detail Peminjaman Alat Produksi Bersama</h4>
      <b-row>
        <b-col>
          <span class="text-primary">Mitra UMKM : {{ detalPengajuan.nama_umkm }}</span><br>
          <small>No Hp : {{ detalPengajuan.no_hp }}</small><br>
          <hr>
          <b-row class="match-height">
            <b-col md="7">
              <b-alert variant="success" show style="" v-show="detalPengajuan.status_peminjaman == 'acc'">
                <div class="alert-body">
                  <span><small>Alat yang dipinjam</small><br>{{ detalPengajuan.nama_alat }} <br><strong>
                      Kode Alat {{ detalPengajuan.kode_alat }}
                    </strong></span><br>
                  <small>Tanggal Peminjaman<br>{{ detalPengajuan.tgl_mulai }} - {{ detalPengajuan.tgl_selesai }}</small>
                </div>
              </b-alert>

              <b-alert variant="warning" show style="" v-show="detalPengajuan.status_peminjaman == 'pengajuan'">
                <div class="alert-body">
                  <span><small>Alat yang dipinjam</small><br>{{ detalPengajuan.nama_alat }} <br><strong>
                      Kode Alat {{ detalPengajuan.kode_alat }}
                      {{ detalPengajuan.kode_item }}</strong></span><br>
                  <small>Tanggal Peminjaman<br>{{ detalPengajuan.tgl_mulai }} - {{ detalPengajuan.tgl_selesai }}</small>
                </div>
              </b-alert>

              <b-alert variant="danger" show style="" v-show="detalPengajuan.status_peminjaman == 'ditolak'">
                <div class="alert-body">
                  <span><small>Alat yang dipinjam</small><br>{{ detalPengajuan.nama_alat }} <br><strong>
                      Kode Alat {{ detalPengajuan.kode_alat }}
                      {{ detalPengajuan.id }}</strong></span><br>
                  <small>Tanggal Peminjaman<br>{{ detalPengajuan.tgl_mulai }} - {{ detalPengajuan.tgl_selesai }}</small>
                </div>
              </b-alert>

              <b-alert variant="primary" show style="" v-show="detalPengajuan.status_peminjaman == 'pengembalian'">
                <div class="alert-body">
                  <span><small>Alat yang dipinjam</small><br>{{ detalPengajuan.nama_alat }} <br><strong>
                      Kode Alat {{ detalPengajuan.kode_alat }}
                      {{ detalPengajuan.kode_item }}</strong></span><br>
                  <small>Tanggal Peminjaman<br>{{ detalPengajuan.tgl_mulai }} - {{ detalPengajuan.tgl_selesai }}</small>
                </div>
              </b-alert>
            </b-col>
            <b-col md="5">
              <div v-if="penecekan_bentrok.st_bentrok == '1' && detalPengajuan.status_peminjaman == 'pengajuan'">
                <b-alert variant="danger" show style="">
                  <div class="alert-body">
                    <b-avatar variant="light-warning" size="50px" rounded=""
                      style="float: left; margin-right: 10px;"><feather-icon icon="AlertTriangleIcon"
                        size='30' /></b-avatar>
                    <small class="mb-1">Pengajuan tidak disarankan untuk divalidasi, dikarenakan posisi alat sedang di
                      gunakan oleh Mitra UMKM <strong>{{ penecekan_bentrok.nama_umkm }}</strong>, dari tanggal
                      <strong>{{ penecekan_bentrok.tgl_mulai }} </strong> dengan<strong> {{
                        penecekan_bentrok.tgl_selesai }}</strong>,<span v-if="penecekan_bentrok.no_hp != null"> No hp {{
                          penecekan_bentrok.no_hp }}</span>
                    </small>

                  </div>
                </b-alert>
              </div>


            </b-col>
          </b-row>
          <div v-if="histori_alat != null">
            <p style="margin-bottom: -2px;"> Mitra yang mengajukan Alat {{ detalPengajuan.nama_alat }}
              Kode Alat {{ detalPengajuan.kode_alat }} {{ detalPengajuan.kode_item }} </p>
            <b-row>
              <b-col md="6" v-for="(histori_alat ) in histori_alat" v-bind:key="histori_alat.id">
                <small> >> Mitra {{
                  histori_alat.nama_umkm }},
                  {{ histori_alat.status }} peminjaman dari tanggal {{ histori_alat.tgl_mulai }} sampai dengan {{
                    histori_alat.tgl_selesai }}
                </small>


              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <detail-pengajuan-pengajuan :detalPengajuan=detalPengajuan :penecekan_bentrok="penecekan_bentrok"
        :histori_alat="histori_alat" @prosesPengajuan="prosesPengajuan"></detail-pengajuan-pengajuan>


    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BRow, BCol, BTabs, BTab, BBadge, BCardText, BAvatar, BCard, BCardBody } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DetailPengajuanPengajuan from './DetailPengajuanPengajuan.vue'
import detail_alat from './DetailAlat.vue'

export default {
  components: {
    BButton,
    BModal,
    BAlert, BRow, BCol, BTabs, BTab, BBadge, BCardText,
    DetailPengajuanPengajuan, BAvatar, BCard, BCardBody,
    detail_alat
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["prosesPengajuan"],
  methods: {
    prosesPengajuan(label, catatan, detalPengajuan) {

      this.$emit('prosesPengajuan', label, catatan, detalPengajuan)
      this.close();
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
    }
  },
  props: {
    detalPengajuan: {},
    penecekan_bentrok: {},
    histori_alat: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>