<template>
  <div>
    <b-row>
      <b-col md="12">
        <statistik :countData="countData" @loadData="loadData"></statistik>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-card>
          <daftar :lis_pengajuan="lis_pengajuan" :label="label" @DetailPengajuan="DetailPengajuan"></daftar>
        </b-card>
      </b-col>
    </b-row>
    <detail-pengajuan :detalPengajuan="detalPengajuan" :penecekan_bentrok="penecekan_bentrok"
      :histori_alat="histori_alat" @prosesPengajuan="prosesPengajuan"></detail-pengajuan>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base';
import axios from '@/config/Axios';
import {
  BRow,
  BCol,
  BForm,
  BCard,
  BCardBody, BFormInput,
  BFormGroup,
} from 'bootstrap-vue'

import statistik from './component/statistik.vue';
import Daftar from './component/Daftar.vue';
import DetailPengajuan from './component/DetailPengajuan.vue';
export default {
  components: {
    BRow, BCol, BForm, BCard, BCardBody, BFormInput, BFormGroup,
    statistik, Daftar,
    DetailPengajuan
  },
  mixins: [Base],
  data() {
    return {
      lis_pengajuan: [],
      histori_alat: [],
      penecekan_bentrok: [],
      status: '',
      countData: [],
      label: 'Semua Transaksi Peminjaman Alat',
      detalPengajuan: {}

    }
  },
  mounted() {
    this.get_lis_pengajuan();
    this.count_pengajuan();
  },
  methods: {
    async prosesPengajuan(label, input, detalPengajuan) {
      if (confirm('Apakah anda yakin akan memproses dataa pengajuan ini')) {

        console.log(input)
        const self = this;
        await axios({
          method: 'POST',
          url: '/api/admin/peminjaman/act',
          data: {
            status: label,
            keterangan_peminjaman: input.catatan,
            id: detalPengajuan.id,
            petugas_pengambilan: input.petugas_pengambilan,
            nama_pengambil: input.nama_pengambil,
            no_hp_pengambil: input.no_hp_pengambil,
            uang_sewa: input.uang_sewa,

            petugas_penerima: input.petugas_penerima,
            keteranganpengembalian: input.keteranganpengembalian,
            nama_pengembalian: input.nama_pengembalian,
            status_barang: input.status_barang.title,
            uang_denda: input.uang_denda,
            id_item_alat_prodiksi: input.id_item_alat_prodiksi

          },
          headers: {
            'Authorization': self.isAuthenticated
          }
        })
          .then(function (response) {
            self.notification('info', "info", 'Transaksi berhasil diproses');
            self.rs = response.data.result;
            self.get_lis_pengajuan();
            self.count_pengajuan();
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      }
    },
    DetailPengajuan(detalPengajuan) {
      this.penecekan_bentrok = [];
      this.detalPengajuan = detalPengajuan;
      this.get_histori_alat(detalPengajuan);
      this.get_penecekan_bentrok(detalPengajuan)
    },

    async get_penecekan_bentrok(item) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/admin/peminjaman/penecekan_bentrok',
        data: {
          id: item.id
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.penecekan_bentrok = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async get_histori_alat(item) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/admin/peminjaman/histori_alat',
        data: {
          id_item_alat_prodiksi: item.id_item_alat_prodiksi,
          id_mitra_umkm: item.id_mitra_umkm
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.histori_alat = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    loadData(act, label) {
      this.lis_pengajuan = [];
      this.status = act;
      this.label = label
      this.get_lis_pengajuan();
    },
    async count_pengajuan() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/admin/peminjaman/count_pengajuan',
        data: {
          status: self.status
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.countData = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    async get_lis_pengajuan() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/admin/peminjaman/get_lis_pengajuan',
        data: {
          status: self.status
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.lis_pengajuan = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  }
}
</script>

<style></style>,